import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {isEmpty, isEqual} from "lodash";
import limApi from "../../apis/limApi";
import graphClient from "../../apis/graphClient";
import {deleteApplicationUserRole, postApplicationUserRole} from "./applicationUsersRolesSlice";
import generator from "generate-password";
import sgMailConfig from "../../apis/sgMailConfig";
import {languageCodeOnly} from "../../../i18n";
import sgMail from "../../apis/sgMail";
import {sleep} from "../../../@utils";
import {getClientUsers} from "./clientUserSlice";

export const getApplicationUsers = createAsyncThunk(
    'applicationUsers/fetchAll',
    async ({queryParams = "", jsonParams}, {rejectWithValue, getState}) => {
        let url = '/application/users';

        try {
            // Here we use the PUT method because of the expected very long parameters string
            // The PUT method is the replacement for the GET method and only returns values
            const response = jsonParams ? await limApi.put(url, jsonParams) : await limApi.get(`${url}?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getApplicationUser = createAsyncThunk(
    'applicationUsers/fetchOne',
    async (idUser, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/application/users/${idUser}`);
            return response.data.user;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postClientUser = createAsyncThunk(
    'applicationUsers/create',
    async (formData, {rejectWithValue, getState, dispatch}) => {
        // normalize formData
        let values = {...formData};
        values.enabled = !!+formData.enabled
        values.idClient = !isEmpty(formData.idClient) ? formData.idClient : null
        values.userName = values.email;
        values.applications = [process.env.REACT_APP_IGL_API_ID_APPLICATION];

        delete values.clientAccounts;
        delete values.changePassword;
        delete values.password;
        delete values.repassword;

        const randomPassword = generator.generate({
            length: '16',
            lowercase: true,
            uppercase: true,
            numbers: true,
            symbols: true,
            strict: true,
            excludeSimilarCharacters: true
        });

        let newUser = {
            accountEnabled: values.enabled,
            displayName: values.firstName + ' ' + values.lastName,
            givenName: values.firstName,
            surname: values.lastName,
            mail: values.email,
            identities: [
                {
                    signInType: "emailAddress",
                    issuer: process.env.REACT_APP_AAD_DOMAIN_NAME,
                    issuerAssignedId: values.email
                }
            ],
            passwordProfile: {
                "password": randomPassword,
                "forceChangePasswordNextSignIn": false
            },
            passwordPolicies: "DisablePasswordExpiration"
        };

        try {
            // Create user in Azure AD B2C
            await graphClient.api('/users').post(newUser).then(res => res.data).then(user => values.idUser = user.id);
        } catch (e) {
            return rejectWithValue(e);
        }

        try {
            // Create user in API
            await limApi.post('/application/users', values);
        } catch (e) {
            try {
                await graphClient.api(`/users/${values.idUser}`).delete();
            } catch (e) {
                await graphClient.api(`/users/${values.idUser}`).delete();
            }
            rejectWithValue(e)
        }

        await dispatch(attachUserRoles(values));

        const msg = {
            from: sgMailConfig.from,
            personalizations: [{
                to: [
                    {
                        email: values.email
                    }
                ],
                dynamic_template_data: {
                    user: {
                        firstName: values.firstName,
                        lastName: values.lastName
                    },
                    appUrl: sgMailConfig.appUrl,
                    resetPasswordUrl: `https://${process.env.REACT_APP_AAD_NAME}.b2clogin.com/${process.env.REACT_APP_AAD_DOMAIN_NAME}/oauth2/v2.0/authorize?p=B2C_1_LB_RESET&client_id=${process.env.REACT_APP_MSAL_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_MSAL_REDIRECT_URI}&scope=openid&response_type=id_token&prompt=login`
                }
            }],
            template_id: sgMailConfig.templatesId.user.create[languageCodeOnly(values.cultureLanguageCode)]
        };

        await sgMail.send(msg).catch(err => {
            console.error(err)
        })
        // for update see https://docs.microsoft.com/en-us/graph/api/user-update?view=graph-rest-1.0&tabs=javascript#response
        // if response is undefined everything is OK else get error
        return values;
    }
)

export const putApplicationUser = createAsyncThunk(
    'applicationUsers/update',
    async (formData, {rejectWithValue, getState, dispatch}) => {
        const stateUser = getState().applicationUsers.user
        if (stateUser.idClient !== formData.idClient) {
            throw Error('Changing Client is not allowed!');
        }

        // normalize formData
        let values = {...formData};
        values.enabled = !!+formData.enabled
        values.idClient = !isEmpty(formData.idClient) ? formData.idClient : null
        values.userName = values.email;
        values.applications = [process.env.REACT_APP_IGL_API_ID_APPLICATION];

        delete values.clientAccounts;
        delete values.changePassword;
        delete values.password;
        delete values.repassword;

        try {
            // Update user in Azure AD B2C
            if (
                values.firstName !== stateUser.firstName ||
                values.lastName !== stateUser.lastName ||
                values.email !== stateUser.email ||
                values.enabled !== stateUser.enabled
            ) {
                const user = {
                    accountEnabled: values.enabled,
                    displayName: values.firstName + ' ' + values.lastName,
                    givenName: values.firstName,
                    surname: values.lastName,
                    mail: values.email,
                    identities: [
                        {
                            signInType: "emailAddress",
                            issuer: process.env.REACT_APP_AAD_DOMAIN_NAME,
                            issuerAssignedId: values.email
                        }
                    ]
                };

                // if (formData.changePassword) {
                //     user.passwordProfile = {
                //         password: formData.password,
                //         forceChangePasswordNextSignIn: false
                //     };
                // }

                await graphClient.api(`/users/${values.idUser}`).update(user);
            }

            await dispatch(attachUserRoles(values));

            const response = await limApi.put(`/application/users/${values.idUser}`, values);

            return response.data.user;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const deleteApplicationUser = createAsyncThunk(
    'applicationUsers/delete',
    async (idUser, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/application/users/${idUser}`);
            await graphClient.api(`/users/${idUser}`).delete();

            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const bulkApplicationUsersImport = createAsyncThunk(
    'applicationUsers/bulkImport',
    async (data, {dispatch, rejectWithValue, getState}) => {
        let success = 0;
        let failed = 0;
        const max = data.users.length;
        const roles = getState().applicationRoles.list;

        dispatch(bulkApplicationUsersImportProgress({
            success,
            failed,
            max
        }));

        for (const user of data.users) {
            user.enabled = data.enabled;
            user.idClient = data.idClient;
            user.userName = user.email;
            user.idRole = roles.find(role => {
                if (data.role.length > 0) {
                    return role.idRole === data.role;
                } else {
                    return role.role === user.role;
                }
            })?.idRole;

            if (data.cultureLanguageCode.length > 0) {
                user.cultureLanguageCode = data.cultureLanguageCode;
            }

            if (data.changePassword) {
                user.password = data.password;
            } else {
                user.password = generator.generate({
                    length: '16',
                    lowercase: true,
                    uppercase: true,
                    numbers: true,
                    symbols: true,
                    strict: true,
                    excludeSimilarCharacters: true
                });
            }

            try {
                // Create user in Azure AD B2C
                await graphClient.api('/users').post({
                    accountEnabled: data.enabled,
                    displayName: user.firstName + ' ' + user.lastName,
                    givenName: user.firstName,
                    surname: user.lastName,
                    mail: user.email,
                    identities: [
                        {
                            signInType: "emailAddress",
                            issuer: process.env.REACT_APP_AAD_DOMAIN_NAME,
                            issuerAssignedId: user.email
                        }
                    ],
                    passwordProfile: {
                        "password": user.password,
                        "forceChangePasswordNextSignIn": false
                    },
                    passwordPolicies: "DisablePasswordExpiration"
                })
                    .then(res => {
                        user.idUser = res.data.id
                    })
                    .catch(e => {
                        throw Error(e.message);
                    });


                // Create user in API
                await limApi.post('/application/users', user)
                    .catch(async e => {
                        await graphClient.api(`/users/${user.idUser}`).delete();
                        throw Error(e.message);
                    });

                // Assign user to role
                await dispatch(postApplicationUserRole({idUser: user.idUser, idRole: user.idRole}));

                // Send user welcome email
                if (data.sendWelcomeEmail) {
                    const msg = {
                        from: sgMailConfig.from,
                        personalizations: [{
                            to: [
                                {
                                    email: user.email
                                }
                            ],
                            dynamic_template_data: {
                                user: {
                                    firstName: user.firstName,
                                    lastName: user.lastName
                                },
                                appUrl: sgMailConfig.appUrl,
                                resetPasswordUrl: `https://${process.env.REACT_APP_AAD_NAME}.b2clogin.com/${process.env.REACT_APP_AAD_DOMAIN_NAME}/oauth2/v2.0/authorize?p=B2C_1_LB_RESET&client_id=${process.env.REACT_APP_MSAL_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_MSAL_REDIRECT_URI}&scope=openid&response_type=id_token&prompt=login`
                            }
                        }],
                        template_id: sgMailConfig.templatesId.user.create[languageCodeOnly(user.cultureLanguageCode)]
                    };

                    await sgMail.send(msg).catch(e => {
                        throw Error(e);
                    })
                }

                success++;
                dispatch(bulkApplicationUsersImportProgress({
                    success,
                    failed,
                    max
                }));
            } catch (e) {
                failed++;
                dispatch(bulkApplicationUsersImportProgress({
                    success,
                    failed,
                    max
                }));
            }
            await sleep(1500);
        }

        if (failed !== 0) {
            const jsonParams = {
                currentPage: 1,
                filterGroups: [
                    {
                        filters: [{
                            field: "idClientAccount",
                            value: `%${data.idClientAccount}%`,
                            operator: "like"
                        }]
                    }
                ]
            }
            await dispatch(getClientUsers({idClient: data.idClient, jsonParams}));
            return rejectWithValue(Error('There is failed requests.'));
        }
        return true;
    }
)

const applicationUsersSlice = createSlice({
    name: "applicationUsers",
    initialState: {
        list: [],
        listLoading: false,
        user: null,
        loading: false,
        bulkImportProgress: undefined
    },
    reducers: {
        resetApplicationUsersList: state => {
            state.list = [];
        },
        resetApplicationUser: state => {
            state.user = null;
        },
        bulkApplicationUsersImportProgress: (state, action) => {
            state.bulkImportProgress = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getApplicationUsers.pending, state => {
                // if (state.list.length === 0) {
                    state.listLoading = true;
                // }
            })
            .addCase(getApplicationUsers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getApplicationUsers.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getApplicationUser.pending, state => {
                state.loading = true;
            })
            .addCase(getApplicationUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(getApplicationUser.rejected, state => {
                state.loading = false;
            })
            .addCase(putApplicationUser.fulfilled, (state, action) => {
                state.user = action.payload;
            })
    }
});

const attachUserRoles = (user) => async (dispatch, getState) => {
    const stateUser = getState().applicationUsers.user || {accounts: []}

    // like this we take into account if the user has the System Administrator role which is kept in the "accounts" object
    // and the other admin roles which are kept in the "roles" object
    const oldRoles = [
        ...stateUser.accounts.reduce((roles, account) => {
            roles.push(...account.roles.map((role) => role.idRole));
            return roles;
        }, []),
        ...stateUser.roles.map(({idRole}) => idRole)
    ];

    const newRoles = user.idClient ? user.accounts.reduce((roles, account) => {
        roles.push(...account.roles);
        return roles;
    }, []) : user.adminRoles.map((idRole) => idRole);

    const equal = isEqual(oldRoles, newRoles)

    if (!equal) {
        // Delete/Remove UserRole
        const deleteRolesPromises = oldRoles
            .filter(old => !newRoles.includes(old))
            .map(old => dispatch(deleteApplicationUserRole({ idUser: user.idUser, idRole: old })));

        // Add UserRole
        const addRolesPromises = newRoles
            .filter(newRole => !oldRoles.includes(newRole))
            .map(newRole => dispatch(postApplicationUserRole({ idUser: user.idUser, idRole: newRole })));

        await Promise.all([...deleteRolesPromises, ...addRolesPromises]);
    }
}

export const {resetApplicationUsersList, resetApplicationUser, bulkApplicationUsersImportProgress} = applicationUsersSlice.actions;

export default applicationUsersSlice.reducer;