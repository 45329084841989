import { combineReducers } from "@reduxjs/toolkit";
import ClientReducer from "./ClientReducer";
import ErrorReducer from "./ErrorReducer";
import boxStockSlice from "../slices/boxStockSlice";
import userSlice from "../slices/userSlice";
import boxPictureSlice from "../slices/boxPictureSlice";
import paymentMethodSlice from "../slices/paymentMethodSlice";
import paymentTermSlice from "../slices/paymentTermSlice";
import activitySlice from "../slices/activitySlice";
import cultureLanguageSlice from "../slices/cultureLanguageSlice";
import applicationUsersRolesSlice from "../slices/applicationUsersRolesSlice";
import presetColorSlice from "../slices/presetColorSlice";
import imageSlice from "../slices/imageSlice";
import applicationUsersSlice from "../slices/applicationUsersSlice";
import accountSlice from "../slices/accountSlice";
import clientUserSlice from "../slices/clientUserSlice";
import boxUserSlice from "../slices/boxUserSlice";
import boxSlice from "../slices/boxSlice";
import providerSlice from "../slices/providerSlice";
import documentSlice from "../slices/documentSlice";
import providerSiteContactSlice from "../slices/providerSiteContactSlice";
import providerSiteSlice from "../slices/providerSiteSlice";
import countrySlice from "../slices/countrySlice";
import dashboardSlice from "../slices/dashboardSlice";
import layoutSlice from "../slices/layoutSlice";
import applicationRolesSlice from "../slices/applicationRolesSlice";
import rolePermissionsSlice from "../slices/rolePermissionsSlice";
import boxCommentSlice from "../slices/boxCommentSlice";
import providerSiteCommentSlice from "../slices/providerSiteCommentSlice";
import boxHistorySlice from "../slices/boxHistorySlice";
import providerSiteHistorySlice from "../slices/providerSiteHistorySlice";
import reportsSlice from "../slices/reportsSlice";
import boxDocumentsSlice from "../slices/boxDocumentsSlice";
import providerSiteDocumentsSlice from "../slices/providerSiteDocumentsSlice";

const RootReducer = combineReducers({
    account: accountSlice,
    activity: activitySlice,
    applicationRoles: applicationRolesSlice,
    applicationUsers: applicationUsersSlice,
    applicationUsersRoles: applicationUsersRolesSlice,
    box: boxSlice,
    boxComment: boxCommentSlice,
    boxDocument: boxDocumentsSlice,
    boxHistory: boxHistorySlice,
    boxPicture: boxPictureSlice,
    boxStock: boxStockSlice,
    boxUser: boxUserSlice,
    clientUser: clientUserSlice,
    clients: ClientReducer,
    country: countrySlice,
    cultureLanguage: cultureLanguageSlice,
    dashboard: dashboardSlice,
    document: documentSlice,
    error: ErrorReducer,
    image: imageSlice,
    layout: layoutSlice,
    paymentMethod: paymentMethodSlice,
    paymentTerm: paymentTermSlice,
    presetColor: presetColorSlice,
    providers: providerSlice,
    providerSiteContact: providerSiteContactSlice,
    providerSiteDocument: providerSiteDocumentsSlice,
    providerSiteComment: providerSiteCommentSlice,
    providerSiteHistory: providerSiteHistorySlice,
    providerSite: providerSiteSlice,
    reports: reportsSlice,
    rolePermissions: rolePermissionsSlice,
    user: userSlice
});

export default RootReducer;
